import {
	type ErrorResponse,
	isRouteErrorResponse,
	useNavigate,
	useParams,
	useRouteError,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"
import type { ReactNode } from "react"
import React from "react"

import { Button } from "@/app/components/ui/button"
import { getErrorMessage } from "@/app/utils/misc"

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => ReactNode | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => <p>{error.data}</p>,
	statusHandlers,
	unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => ReactNode | null
}) {
	const error = useRouteError()

	captureRemixErrorBoundaryError(error)
	const params = useParams()

	if (typeof document !== `undefined`) {
		console.error(error)
	}

	const navigate = useNavigate()
	return (
		<div className="gap-4 flex flex-col items-center text-center justify-center text-h2 container min-w-full min-h-full text-black dark:text-warmGray-100 bg-transparent">
			<div>
				{isRouteErrorResponse(error)
					? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
							error,
							params,
						})
					: unexpectedErrorHandler(error)}
			</div>
			<Button
				onClick={() => {
					navigate(-1)
				}}
				variant="cta"
			>
				Return
			</Button>
		</div>
	)
}
